<template>
  <div class="auth-form">
    <div v-if="!isNotice" class="auth-form__content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :hide-required-asterisk="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-form-item label="E-mail" prop="login">
          <el-input v-model="form.login" ref="login" type="text" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary el-button--stretch" native-type="submit">
            Восстановить пароль
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="isNotice" class="auth-form__notice">
      {{ message }}
    </div>
    <div class="auth-form__footer">
      <router-link
        v-if="!isNotice"
        class="login-page__link"
        :to="{ name: 'Login' }"
      >
        Войти в систему
      </router-link>
      <router-link
        v-if="isNotice"
        class="login-page__link"
        :to="{ name: 'ResetPassword' }"
      >
        Изменить пароль
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgetPassword',
  data() {
    const checkLogin = (rule, value, callback) => {
      if (this.authErrors && this.authErrors.email) {
        callback(new Error(`${this.authErrors.email}`))
      } else {
        callback()
      }
    }
    return {
      form: {
        login: '',
      },
      rules: {
        login: [
          {
            required: true,
            message: 'Введите e-mail адрес',
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: 'Выбранное значение для e-mail адреса некорректно.',
            trigger: ['blur'],
          },
          { validator: checkLogin },
        ],
      },
      isNotice: false,
      message: '',
    }
  },
  computed: {
    authErrors() {
      return this.$store.state.Auth.errors
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$store.commit('Auth/setData', { label: 'errors', data: null })
      },
    },
  },
  mounted() {
    this.$refs.login.focus()
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.sendCode()
        } else {
          return false
        }
      })
    },
    sendCode() {
      const data = {
        email: this.form.login,
      }

      this.$store
        .dispatch('Auth/sendCode', data)
        .then(() => {
          this.isNotice = true
          this.message = 'Письмо с кодом смены пароля отправлено на Ваш E-mail'
        })
        .catch(err => {
          console.error(err.response)
          this.$refs['form'].validate(() => {})
        })
    },
  },
}
</script>

<style scoped></style>
